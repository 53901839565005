import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { membershipType } from "utils/constants";
import { getDataFromLocalStorage } from "utils/helpers";

const NewMyGroups = () => {
    const navigate = useNavigate();
    const { myGroupsList } = useSelector((state) => ({
        myGroupsList: state.global.myGroupsList,
    }));
    return (
        <div className="">
            <div className="bb cpb-16">
                <div className="cps-24 cpe-21 cpt-24 d-flex justify-content-between flex-wrap gap-2">
                    <div className="text-16-400-20 color-title-navy font-poppins text-nowrap">
                        My Groups
                    </div>
                    {myGroupsList?.length > 10 && (
                        <div
                            className={`text-14-500-18 color-7cff pointer text-nowrap bottom-border"
            }`}
                            onClick={() => {
                                const userFlag = membershipType.find(
                                    (o) => o.id === getDataFromLocalStorage("user_type")
                                )?.type;
                                navigate(
                                    `/${userFlag}/network-management/network/groups/my-groups`
                                );
                            }}
                        >
                            <span className="d-flex gap-2 align-items-center">
                                View All
                                <i className="bi bi-chevron-right" />
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div className="cps-24 cpe-21 cpt-24 cpb-22 cmb-20">
                {myGroupsList?.length === 0 ? (
                    <div className="center-flex pt-5 pb-5">No Groups Found</div>
                ) : (
                    <div className="group-list-block">
                        {myGroupsList?.map((o, i) => {
                            return (

                                <div className={`${myGroupsList?.length - 1 !== i ? "bb" : ""} group-item ${i % 2 === 0 ? "bg-white" : "bg-f6ff"}`} key={i}>
                                    <div>{o?.name}</div>
                                    <div
                                        className={`text-14-500-18 color-7cff pointer text-nowrap bottom-border"
            }`}
                                        onClick={() => {
                                            const userFlag = membershipType.find(
                                                (o) => o.id === getDataFromLocalStorage("user_type")
                                            )?.type;
                                            navigate(
                                                `/${userFlag}/network-management/network/groups/my-groups`
                                            );
                                        }}
                                    >
                                        <span className="d-flex gap-2 align-items-center">
                                            View All
                                            <i className="bi bi-chevron-right" />
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            {myGroupsList?.length > 10 && (
                <div
                    className="cpb-20 text-14-500-18 color-7cff pointer d-flex align-item-center justify-content-center gap-2"
                    onClick={() => {
                        const userFlag = membershipType.find(
                            (o) => o.id === getDataFromLocalStorage("user_type")
                        )?.type;
                        navigate(
                            `/${userFlag}/network-management/network/groups/my-groups`
                        );
                    }}
                >
                    View All Groups
                    <i className="bi bi-chevron-right" />
                </div>
            )}
        </div>
    );
};

export default NewMyGroups;
