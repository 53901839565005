import React, { useEffect, useRef, useState } from "react";
import Loader from "components/Layout/Loader";
import Label from "../Label";
import { titleCaseString } from "utils/helpers";
import "./CreatablePricingDropdown.scss";
import { icons } from "utils/constants";
import { useDispatch } from "react-redux";
import { throwError } from "store/slices";
const CreatablePricingDropdown = ({
  id,
  value,
  error,
  onChange,
  isClearable,
  options,
  isLoading,
  label,
  isRequired,
  labelClass,
  isBadge,
  selectedList,
  disabled
}) => {
  const dispatch = useDispatch();
  const [list, setList] = useState(options || []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dropdownRef = useRef();
  const filteredPricingList = list;
  

  useEffect(() => {
    const savedCategories =
      JSON.parse(localStorage.getItem("categories")) || [];
    setList([...new Set([...options, ...savedCategories])]); // Combine and deduplicate
  }, [options]);

  const handleItemSelect = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    const isAlreadySelected = selectedList?.some(
      (selected) => selected?.category === item?.name
    );

    if (isAlreadySelected) {
      dispatch(throwError({ message: `${item?.name} is already selected.` }));
      return;
    }

    onChange({
      target: {
        id: id,
        value: item.name || "",
        data: item,
      },
    });
    setSearchText("");
    setIsMenuOpen(false);
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const handleCreatePricing = (e) => {
    e.preventDefault();
    const newCategory = {
      id: null, // Temporary ID for local list
      name: titleCaseString(searchText),
      status: "0",
    };

    const isAlreadySelected = selectedList?.some(
      (selected) => selected?.category === newCategory?.name
    );
    const isAlreadyInList = list?.some(
      (item) => item?.name?.toLowerCase() === searchText?.toLowerCase()
    );

    if (isAlreadySelected || isAlreadyInList) {
      dispatch(throwError({ message: `${searchText} is already present.` }));
      return;
    }

    onChange({
      target: {
        id: id,
        value: newCategory?.name,
        data: newCategory,
      },
    });
    const savedCategories =
      JSON.parse(localStorage.getItem("categories")) || [];
    savedCategories.push(newCategory);
    localStorage.setItem("categories", JSON.stringify(savedCategories));

    // Update the list state
    setList((prev) => [...new Set([...prev, newCategory])]);

    setSearchText("");
    setIsMenuOpen(false);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // const selectedPricing = filteredPricingList?.find(
  //   (item) => +item.id === +value
  // );

  const selectedPricing = list?.find((item) => {
    return item?.id?.toString() === value?.toString() || item?.name === value;
  });

  const displayList = searchText
    ? filteredPricingList?.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : filteredPricingList;

  const isCreatable = displayList?.length === 0 && searchText;

  return (
    <div
      id="pricing-dropdown-container"
      ref={dropdownRef}
      // className="position-relative"
      className={`position-relative ${disabled ? "disabled" : ""}`}

    >
      {label && (
        <Label label={label} required={isRequired} className={labelClass} />
      )}
      {isBadge && <span className="label-badge">Updated</span>}
      <div
        className={`pricing-input-block ${
          selectedPricing ? "remove-placeholder-color" : ""
        } ${disabled ? "disabled" : ""}`}
        onClick={() => !disabled && setIsMenuOpen(true)}
      >
        <input
          type="text"
          // value={searchText}
          value={searchText || ""}
          placeholder={
            titleCaseString(selectedPricing?.name) || "Select Pricing"
          }
          onChange={handleSearchChange}
          disabled={disabled}
        />
        {isClearable && selectedPricing && (
          <i
            className="bi bi-x close-icon"
            onClick={(e) => handleItemSelect(e, { id: "" })}
          />
        )}
        {!isClearable &&  (
          <i className="bi bi-chevron-down dropdown-icon" />
        )}
        {isMenuOpen && (
          <div className="pricing-list iferp-scroll">
            {isCreatable ? (
              <div
                className="pricing-item create-option d-flex align-items-center gap-1"
                onClick={handleCreatePricing}
              >
                <span>Create</span>
                <span>"{searchText}"</span>
                <span>
                  <img src={icons?.AddCircle} alt="plus" />
                </span>

                {isLoading && <Loader size="sm" />}
              </div>
            ) : (
              displayList?.map((item, index) => {
                const isSelected = selectedList?.some(
                  (selected) => selected?.category === item?.name
                );
                return (
                  <div
                    key={index}
                    // className="pricing-item d-flex gap-2"
                    className={`pricing-item d-flex gap-2 ${
                      isSelected ? "selected-category" : ""
                    }`}
                    onClick={(e) => handleItemSelect(e, item)}
                  >
                    {item.name}
                    {!item?.id && (
                      <span>
                        <img src={icons?.AddCircle} alt="plus" />
                      </span>
                    )}
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
      {error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  );
};

export default CreatablePricingDropdown;

/*import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "components/Layout/Loader";
import "./CreatablePricingDropdown.scss";
import Label from "../Label";
import { titleCaseString } from "utils/helpers";
const CreatablePricingDropdown = ({
  id,
  value,
  error,
  onChange,
  isClearable,
  options,
  isLoading,
  label,
  isRequired,
  labelClass,
  isBadge
//   onCategoryCreate,
}) => {
    const [list, setList] = useState(options || []);
  
  //   const filteredPricingList = list?.filter((item) => item.status === "1");
  const filteredPricingList = list;

  const dropdownRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   const savedCategories = JSON.parse(localStorage.getItem("categories")) || [];
  //   setList([...options, ...savedCategories]); // Merge options with saved categories
  // }, [options]);
  useEffect(() => {
    const savedCategories = JSON.parse(localStorage.getItem("categories")) || [];
    setList([...new Set([...options, ...savedCategories])]); // Combine and deduplicate
  }, [options]);

  const handleItemSelect = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    onChange({
      target: {
        id: id,
        value: item.name,
        data: item,
      },
    });
    setSearchText("");
    setIsMenuOpen(false);
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const handleCreatePricing = (e) => {
    e.preventDefault();
    const newCategory = {
      id: null, // Temporary ID for local list
      name: searchText,
      status: "0",
    };
    console.log("newCategory", newCategory);
    const savedCategories = JSON.parse(localStorage.getItem("categories")) || [];
    savedCategories.push(newCategory);
    localStorage.setItem("categories", JSON.stringify(savedCategories));

    // Update the list state
    setList((prev) => [...new Set([...prev, newCategory])]);
    // setList((prev) => [...prev, newCategory]);
    setSearchText("");
    setIsMenuOpen(false);
  };

  // const handleAddCategories = async () => {
  //   setIsLoading(true);

  //   // Combine original and newly created categories
  //   const updatedCategories = [...filteredPricingList, ...localPricingList];

  //   try {
  //     await onAddCategories(updatedCategories); // Pass the list to the parent function
  //     setLocalPricingList([]); // Clear local list after successful save
  //   } catch (error) {
  //     console.error("Failed to save categories:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  const selectedPricing = filteredPricingList?.find(
    (item) => +item.id === +value
  );
  const displayList = searchText
    ? filteredPricingList?.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : filteredPricingList;

  const isCreatable = displayList?.length === 0 && searchText;

  console.log("list",list)
console.log("filteredPricingList",filteredPricingList)
console.log("displayList",displayList);

  return (
    <div id="pricing-dropdown-container" ref={dropdownRef}>
    {label && (
        <Label label={label} required={isRequired} className={labelClass} />
      )}
      {isBadge&& (<span className="label-badge">Updated</span>)}
      <div
        className={`pricing-input-block ${
          selectedPricing ? "remove-placeholder-color" : ""
        }`}
        onClick={() => setIsMenuOpen(true)}
      >
        <input
          type="text"
          value={searchText}
          placeholder={titleCaseString(selectedPricing?.name) || "Select Pricing"}
          onChange={handleSearchChange}
        />
        {isClearable && selectedPricing && (
          <i
            className="bi bi-x close-icon"
            onClick={(e) => handleItemSelect(e, { id: "" })}
          />
        )}
        {!isClearable && !selectedPricing && (
          <i className="bi bi-chevron-down dropdown-icon" />
        )}
        {isMenuOpen && (
          <div className="pricing-list iferp-scroll">
            {isCreatable ? (
              <div
                className="pricing-item create-option d-flex align-items-center gap-1"
                onClick={handleCreatePricing}
              >
                <span>Create</span>
                <span>"{searchText}"</span>
                {isLoading && <Loader size="sm" />}
              </div>
            ) : (
              displayList?.map((item, index) => (
                <div
                  key={index}
                  className="pricing-item"
                  onClick={(e) => handleItemSelect(e, item)}
                >
                  {item.name}
                </div>
              ))
            )}
          </div>
        )}
      </div>
      {error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  );
};

export default CreatablePricingDropdown;*/
