
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { orderBy } from "lodash";
import moment from "moment";
import { fetchUserEvents } from "store/slices";
import { getEventDate, getUserType, objectToFormData } from "utils/helpers";
import { icons } from "utils/constants";

const NewUpcomingEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eventList, setEventList] = useState([]);
  const getProfiles = async () => {
    const formData = objectToFormData({event_type:1})
    const response = await dispatch(fetchUserEvents(formData));
    let data = [];
    if (response?.data?.event_details) {      
      data = orderBy(response.data.event_details, "event_start_date")
        ?.filter((o) => !moment().isAfter(o.event_start_date))
        ?.slice(0, 2);
    }
    setEventList(data);
  };
  useEffect(() => {
    getProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const memberType = getUserType();
  
  return (
    
    <div className="">
      <div className="bb cpb-16">
        <div className="cps-24 cpe-21 cpt-24 d-flex justify-content-between flex-wrap gap-2">
          <div className="text-16-400-20 color-title-navy font-poppins text-nowrap">
            Upcoming Conferences
          </div>
          <div
            className={`text-14-500-18 color-7cff pointer text-nowrap bottom-border ${
              eventList.length > 0 ? "" : "d-none"
            }`}
            onClick={() => {
              navigate(`/${memberType}/conferences-and-events`);
            }}
          >
            <span className="d-flex gap-2 align-items-center">
              View All
              <i className="bi bi-chevron-right" />
            </span>
          </div>
        </div>
      </div>

      <div className="cps-24 cpe-21 cpt-14 cpb-22">
        {eventList?.length === 0 ? (
          <div className="center-flex pt-5 pb-5">No Events Found</div>
        ) : (
          eventList?.map((elm, index) => {
            return (
              <div
                className={`${eventList?.length-1 !== index ? "cmb-14":""} cps-14 cpe-14 cpt-14 cpb-14 five-box-border`}
                key={index}
              >
                <div className="cmb-14 text-14-400-24 color-new-car text-dots">
                  {elm?.event_name}
                </div>
                <div className="d-flex cmb-15 gap-3">
                  <div className="text-14-300-18 color-black-olive align-items-center d-flex gap-2">
                    <img src={icons.calendarEvent} alt="calender" />
                    {getEventDate(elm?.event_start_date, elm?.event_end_date)}
                  </div>
                  {/* <div className="text-14-300-18 color-black-olive">
                      <img src={icons.clockEvent} alt="clock" />
                      {`${elm?.city_name ? `${elm?.city_name},` : ""} ${
                        elm?.country_name
                      }`}
                    </div> */}
                  <div className="text-14-300-18 color-black-olive align-items-center d-flex gap-2">
                    <img src={icons.locationEvent} alt="location" />
                    {`${elm?.city_name ? `${elm?.city_name},` : ""} ${
                      elm?.country_name
                    }`}
                  </div>
                </div>
                {/* <div>
                    <div className="text-14-300-18 color-black-olive">
                      <img src={icons.locationEvent} alt="location" />
                      {`${elm?.city_name ? `${elm?.city_name},` : ""} ${
                        elm?.country_name
                      }`}
                    </div>
                  </div> */}
              </div>
            );
          })
        )}
      </div>
    </div>
    
  );
};

export default NewUpcomingEvents;
