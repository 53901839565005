import React from "react";
import Modal from "../Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "components/form/TextInput";
import {
  convertString,
  numberOnlyFromInput,
  titleCaseString,
} from "utils/helpers";
import Location from "components/form/Location";
import Button from "components/form/Button";
import { isEqual } from "lodash";
import { errorMsgForMobile } from "utils/constants";

const AddAuthorPopup = ({ onHide, setAuthorData, id,typedData }) => {
  const initialValues = {
    author_name: "",
    author_country: "",
    author_email_id: typedData||"",
    author_whatsapp_number: "",
    author_country_code: "IN",
    author_mobile_no_length: 10,
  };

  const validationSchema = Yup.object().shape({
    author_name: Yup.string().required("Name is required."),
    author_email_id: Yup.string()
      .required("Email is required.")
      .email("Email must be a valid email"),
    author_whatsapp_number: Yup.lazy((_, obj) => {
      const { author_country_code, author_mobile_no_length } =
        obj?.parent || {};
      if (author_country_code) {
        return Yup.string()
          .required("Phone number is required.")
          .min(
            author_mobile_no_length,
            errorMsgForMobile(author_mobile_no_length)?.min
          )
          .max(
            author_mobile_no_length,
            errorMsgForMobile(author_mobile_no_length)?.max
          );
      } else {
        return Yup.string();
      }
    }),
    author_country: Yup.string().required("Country is required."),
  });

  const handleAddAuthor = (data) => {
    setAuthorData({ id: id, value: data });
  };
  return (
    <Modal
      titleBesideClose="Add Author"
      onHide={onHide}
      isTitleBorder
      isTitleVerticalCenter
    >
      <div className="cps-25 cpe-25 cpt-30 cpb-30">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleAddAuthor}
        >
          {(props) => {
            const {
              values,
              errors,
              handleChange,
              setFieldValue,
              handleSubmit,
              resetForm,
            } = props;
            return (
              <form>
                <div className="row">
                  <div className="col-md-6 cmb-25">
                    <TextInput
                      label="Name*"
                      placeholder="Name"
                      id="name"
                      value={values.author_name}
                      error={errors.author_name}
                      onChange={(e) => {
                        setFieldValue(
                          "author_name",
                          titleCaseString(e.target.value)
                        );
                      }}
                    />
                  </div>

                  <div className="col-md-6 cmb-25">
                    <Location
                      type="country"
                      data={{
                        label: "Country*",
                        id: "author_country",
                        placeholder: "Country*",
                        value: values.author_country,
                        error: errors.author_country,
                        onChange: (e) => {
                          setFieldValue(
                            "author_country",
                            e?.target?.data?.country
                          );
                          handleChange(e);
                        },
                      }}
                    />
                  </div>

                  <div className="col-md-6 cmb-25">
                    <TextInput
                      label="Email*"
                      placeholder="Email"
                      id="author_email_id"
                      value={values.author_email_id}
                      onChange={(e) => {
                        handleChange(convertString(1, e));
                      }}
                      error={errors.author_email_id}
                    />
                  </div>

                  <div className="col-md-6 cmb-25">
                    <TextInput
                      isPhoneNumber
                      label="Phone Number*"
                      placeholder="Phone Number"
                      id="author_whatsapp_number"
                      value={values.author_whatsapp_number}
                      error={errors.author_whatsapp_number}
                      phoneNumberData={{
                        id: "author_country_code",
                        value: values.author_country_code,
                      }}
                      onChange={(e) => {
                        if (e.target.id === "author_whatsapp_number") {
                          handleChange(numberOnlyFromInput(e));
                        } else {
                          handleChange(e);
                          handleChange({
                            target: { id: "author_whatsapp_number", value: "" },
                          });
                          setFieldValue(
                            "author_mobile_no_length",
                            e.target.length
                          );
                        }
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-center gap-4">
                    <Button
                      text="Cancel"
                      btnStyle="light-outline"
                      className="cps-30 cpe-30"
                      onClick={resetForm}
                    />
                    <Button
                      text={"Invite"}
                      btnStyle="primary-dark"
                      className="cps-30 cpe-30"
                      onClick={handleSubmit}
                      disabled={isEqual(initialValues, values)}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddAuthorPopup;
