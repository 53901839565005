import Card from "components/Layout/Card";
import React from "react";
import { icons } from "utils/constants";

const NewIFERPCounts = ({dashboardData}) => {
  const {
    conference_counting,
    webinar_counting,
    publication_counting,
    members_counting,
  } = dashboardData || {};

  return (
    <div className="row">
      <div className="col-md-3 mb-3">
        <Card className="d-flex gap-3 align-items-center counter-block h-100 cpt-20 cpb-20">
          <div className="counter-image-block">
            <img src={icons.newGroups} alt="img" />
          </div>
          <div>
            <div className="text-20-400-25 color-1717 mb-2">
              {conference_counting}
              {conference_counting && `+`}
            </div>
            <div className="text-16-400-20 color-black-olive">Conferences</div>
          </div>
        </Card>
      </div>
      <div className="col-md-3 mb-3">
        <Card className="d-flex gap-3 align-items-center counter-block h-100 cpt-20 cpb-20">
          <div className="counter-image-block">
            <img src={icons.newGroups} alt="img" />
          </div>
          <div>
            <div className="text-20-400-25 color-1717 mb-2">
              {webinar_counting}
              {webinar_counting && `+`}
            </div>
            <div className="text-16-400-20 color-black-olive">Webinars</div>
          </div>
        </Card>
      </div>
      <div className="col-md-3 mb-3">
        <Card className="d-flex gap-3 align-items-center counter-block h-100 cpt-20 cpb-20">
          <div className="counter-image-block">
            <img src={icons.newGroups} alt="img" />
          </div>
          <div>
            <div className="text-20-400-25 color-1717 mb-2">
              {publication_counting}
              {publication_counting && `+`}
            </div>
            <div className="text-16-400-20 color-black-olive">Publications</div>
          </div>
        </Card>
      </div>
      <div className="col-md-3 mb-3">
        <Card className="d-flex gap-3 align-items-center counter-block h-100 cpt-20 cpb-20">
          <div className="counter-image-block">
            <img src={icons.newGroups} alt="img" />
          </div>
          <div>
            <div className="text-20-400-25 color-1717 mb-2">
              {members_counting}
              {members_counting && `+`}
            </div>
            <div className="text-16-400-20 color-black-olive">Members</div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default NewIFERPCounts;
