import Loader from "components/Layout/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllCareer } from "store/slices";
import { icons } from "utils/constants";
import {
  getDataFromLocalStorage,
  getUserType,
  objectToFormData,
} from "utils/helpers";

const NewCareerOppertunities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [tableList, setTableList] = useState([]);

  const getCareer = async () => {
    let forData = objectToFormData({ user_id: getDataFromLocalStorage("id") });
    const response = await dispatch(fetchAllCareer(forData));
    setTableList(response?.data?.career_details || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getCareer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userType = getUserType();
  const filterCareerList = tableList?.filter((o) =>
    o?.member_category.includes(getDataFromLocalStorage("membership_plan_id"))
  );

  return (
    <div>
      <div className="bb cpb-16">
        <div className="cps-24 cpe-21 cpt-24 d-flex justify-content-between flex-wrap gap-2">
          <div className="text-16-400-20 color-title-navy font-poppins text-nowrap">
            Career Opportunities
          </div>

          <div
            className={`text-14-500-18 color-7cff pointer text-nowrap bottom-border"
            }`}
            onClick={() => {
              navigate(`/${userType}/career-support`);
            }}
          >
            <span className="d-flex gap-2 align-items-center">
              View All
              <i className="bi bi-chevron-right" />
            </span>
          </div>
        </div>
      </div>

      <div className="cps-24 cpe-21 cpt-24 cpb-22 cmb-36">
        {isLoading ? (
          <div className="center-flex cpt-50 cpb-50">
            <Loader size="md" />
          </div>
        ) : filterCareerList?.length === 0 ? (
          <div className="center-flex cpt-50 cpb-50">No Career Found</div>
        ) : (
          filterCareerList?.slice(0, 10)?.map((o, i) => {
            return (
              <div className="d-flex align-items-center gap-3 cmb-16" key={i}>
                <img src={icons.NewTickCircle} alt="circle" />
                <div className="text-14-300-24 color-1717">
                  {o?.career_category}
                </div>
              </div>
            );
          })
        )}
      </div>

      {(!isLoading && filterCareerList?.length > 10) && <div
        className="cpb-19 text-14-500-18 color-7cff pointer d-flex align-item-center justify-content-center gap-2"
        onClick={() => {
          navigate(`/${userType}/career-support`);
        }}
      >
        View More Career
        <i className="bi bi-chevron-right" />
      </div>}
    </div>
  );
};

export default NewCareerOppertunities;
