import Modal from "components/Layout/Modal";
import NewRegistrationEventForm from "../../EventForm/NewRegistrationEventForm";

const NewEditRegistartion = ({ onHide, eventId, fetchEventDetails }) => {
  return (
    <Modal onHide={onHide} title="Registration">
      <div className="">
        <NewRegistrationEventForm
          eventId={eventId}
          onHide={onHide}
          fetchEventDetails={fetchEventDetails}
          isModal
        />
      </div>
    </Modal>
  );
};
export default NewEditRegistartion;
