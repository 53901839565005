import Card from "components/Layout/Card";
import React, { useEffect, useState } from "react";
import Button from "components/form/Button";
import { useNavigate } from "react-router-dom";
import { getUserType } from "utils/helpers";
import { icons } from "utils/constants";
import "./DashboardPapersInfo.scss";
const steps = [
  "Paper Verification",
  "Plagiarism",
  "Review",
  "Acceptance",
  "Registration",
];
const DashboardPapersInfo = ({ label, data }) => {
  const navigate = useNavigate();
  const memberType = getUserType();
  const [selectedPaper, setSelectedPaper] = useState(null);

  useEffect(() => {
    if (data?.length > 0) {
      setSelectedPaper(data?.[0]);
    }
  }, [data]);
  return (
    <Card className={" submitted-paper-status-id h-100"}>
      <div className="bb cpb-16">
        <div className="cps-24 cpe-21 cpt-24 d-flex justify-content-between flex-wrap gap-2">
          <div className="text-16-400-20 color-title-navy font-poppins text-nowrap">
            {label}
          </div>
          {data?.length > 3 && (
            <div
              className={`text-14-500-18 color-7cff pointer text-nowrap bottom-border`}
              onClick={() => {
                navigate(`/${memberType}/my-profile/my-events`);
              }}
            >
              <span className="d-flex gap-2 align-items-center">
                View All
                <i className="bi bi-chevron-right" />
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="cps-24 cpe-24 cpt-24 cpb-30">
        {data?.length === 0 ? (
          <div className="center-flex pt-5 pb-5">No Papers Found</div>
        ) : (
          data?.slice(0, 3)?.map((elem, index) => {
            const { abstract_id, paper_title, event_id, id, stage } = elem;
            const isOpen = selectedPaper === elem;
            return (
              <div className="border" key={index}>
                <div
                  className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-400-18 color-black-olive pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4fc"
                  onClick={() => {
                    setSelectedPaper(isOpen ? null : elem);
                  }}
                >
                  <span>{`${abstract_id} - ${paper_title}`}</span>

                  <i
                    className={`${
                      isOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"
                    }`}
                    style={{ fontSize: "15px" }}
                  />
                </div>
                {isOpen && (
                  <div
                    className={`${
                      data ? "cpb-20" : "cpb-60"
                    } d-flex flex-column cpt-22 cps-24 cpe-24 w-100`}
                  >
                    <div
                      className={`${
                        data ? "cmb-60" : ""
                      } d-flex align-items-center justify-content-center w-100 time-line-container`}
                    >
                      {steps.map((o, stepIndex) => {
                        const currentStage = +stage;
                        const lastIndex = steps?.length - 1;
                        const isCompleted = stepIndex < currentStage;
                        const isActive = stepIndex + 1 === currentStage + 1;

                        return (
                          <React.Fragment key={stepIndex}>
                            <div className="d-flex justify-content-start align-items-center position-relative">
                              <div
                                className={`steps-block ${
                                  isActive || isCompleted
                                    ? "active"
                                    : "not-active"
                                }`}
                              >
                                {isCompleted ? (
                                  <img src={icons?.RightIcon} alt="right" />
                                ) : (
                                  stepIndex + 1
                                )}
                              </div>
                              <div
                                className={`step-label ${
                                  isActive || isCompleted
                                    ? "active-label"
                                    : "in-active-label"
                                }`}
                              >
                                {o}
                              </div>
                            </div>
                            {lastIndex !== stepIndex && (
                              <div className="sparator-block">
                                <span
                                  className={`hr-line ${
                                    isCompleted
                                      ? "active-line"
                                      : "in-active-line"
                                  }`}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className="d-flex">
                      <Button
                        text={"View Full Details"}
                        btnStyle={"primary-dark"}
                        className={"h-30 cps-20 cpe-20 text-12-500-15"}
                        onClick={() => {
                          navigate(
                            `/${memberType}/my-profile/my-events/abstract/tracking/${event_id}/${id}`
                          );
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>

      {data?.length > 3 && (
        <div
          className="cpb-19 text-14-500-18 color-7cff pointer d-flex align-item-center justify-content-center gap-2"
          onClick={() => {
            navigate(`/${memberType}/my-profile/my-events`);
          }}
        >
          View All Papers
          <i className="bi bi-chevron-right" />
        </div>
      )}
    </Card>
  );
};

export default DashboardPapersInfo;
