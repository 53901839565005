import OverlapProfile from "components/Layout/OverlapProfile";
import RequestHandleLayout from "components/Layout/RequestHandleLayout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchNetworkUsers } from "store/slices";
import { icons, membershipType, profilePath } from "utils/constants";
import { generatePreSignedUrl, getDataFromLocalStorage } from "utils/helpers";
const adminList = [
  "admin",
  "Admin",
  "iferp",
  "IFERP",
  "iferp admin",
  "IFERP Admin",
];
const NewPeopleYouMayKnow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);

  const getUsers = async () => {
    const response = await dispatch(fetchNetworkUsers());

    if (response?.data?.user_details) {
      const newResponse = response?.data?.user_details?.map(async (o) => {
        let newProfileImage = "";
        if (o?.profile_photo_path) {
          newProfileImage = await generatePreSignedUrl(
            o?.profile_photo_path,
            profilePath
          );
        }
        return {
          ...o,
          s3URL: newProfileImage,
        };
      });
      const results = await Promise.all(newResponse);
      setUserList(results || []);
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      <div className="bb cpb-16">
        <div className="cps-24 cpe-21 cpt-24 d-flex justify-content-between flex-wrap gap-2">
          <div className="text-16-400-20 color-title-navy font-poppins text-nowrap">
            People You May Know
          </div>
          {userList?.length > 6 && (
            <div
              className={`text-14-500-18 color-7cff pointer text-nowrap bottom-border"
            }`}
              onClick={() => {
                const userFlag = membershipType.find(
                  (o) => o.id === getDataFromLocalStorage("user_type")
                )?.type;
                navigate(
                  `/${userFlag}/network-management/network/network/sent-requests`
                );
              }}
            >
              <span className="d-flex gap-2 align-items-center">
                View All
                <i className="bi bi-chevron-right" />
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="cps-24 cpe-21 cpt-24 cpb-22 cmb-40">
        {userList?.length === 0 ? (
          <div className="center-flex pt-5 pb-5">No People Found</div>
        ) : (
          <div className="row">
            {userList?.slice(0, 6)?.map((o, i) => {
              const isAdmin = adminList.includes(o?.name);

              return (
                <div
                  className={`${
                    userList?.length - 1 !== i ? "cmb-20" : ""
                  } col-md-6`}
                >
                  <div className="people-know-block hover-card" key={i}>
                    <div className="image-block">
                      {o?.s3URL ? (
                        <img
                          src={isAdmin ? icons.roundLogo : o?.s3URL}
                          alt="journal"
                          className="fit-image fill"
                        />
                      ) : (
                        <div className="name-block">
                          {o?.name?.slice(0, 2).toUpperCase() || "NA"}
                        </div>
                      )}
                    </div>
                    <div className="flex-grow-1">
                      <div className="text-14-500-18 color-new-car cmb-12 d-flex gap-2">
                        {o?.name}
                        {o?.badge_new === "1" && (
                          <span className="new-people">NEW</span>
                        )}
                      </div>
                      <div className="text-14-300-18 color-black-olive cmb-12 d-flex gap-2 align-items-center">
                        <img src={icons.locationEvent} alt="location" />
                        {o?.state || o?.country ? (
                          <>
                            {o?.state ? `${o?.state}, ` : ""}
                            {o?.country ? `${o?.country}` : ""}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="text-14-300-18 color-black-olive cmb-12 d-flex align-items-center gap-2">
                        <OverlapProfile
                          userList={o?.followers_details || []}
                          NoText
                          isDashboard
                        />
                        {o?.followers} Mutual Connection
                      </div>
                      <div>
                        <RequestHandleLayout
                          receiverId={o?.id}
                          newSendRequest
                          isDashboard
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {userList?.length > 6 && (
        <div
          className="cpb-29 text-14-500-18 color-7cff pointer d-flex align-item-center justify-content-center gap-2"
          onClick={() => {
            const userFlag = membershipType.find(
              (o) => o.id === getDataFromLocalStorage("user_type")
            )?.type;
            navigate(
              `/${userFlag}/network-management/network/network/sent-requests`
            );
          }}
        >
          View All Suggestions
          <i className="bi bi-chevron-right" />
        </div>
      )}
    </div>
  );
};

export default NewPeopleYouMayKnow;
