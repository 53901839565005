import Card from "components/Layout/Card";
import MembershipBenefits from "components/Layout/MembershipBenefits";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMembershipDetails } from "store/slices";
import { membershipType } from "utils/constants";
import { getDataFromLocalStorage, objectToFormData } from "utils/helpers";

const NewMembershipAndBenefits = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = getDataFromLocalStorage();
  const [planDetails, setPlanDetails] = useState({});
  const handelMembershipDetails = async (id) => {
    if (id) {
      const response = await dispatch(
        getMembershipDetails(objectToFormData({ id: id }))
      );

      setPlanDetails(response?.data || {});
    }
  };
  useEffect(() => {
    handelMembershipDetails(membership_plan_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { member_id, membership_plan_id, user_type } = userData;
  const { plan_name, benefit_detail } = planDetails || {};
  const userFlag = membershipType.find((o) => o.id === user_type)?.type;
  return (
    <>
      <Card className={"cmb-16"}>
        <div className="bb cpb-16">
          <div className="cps-24 cpe-21 cpt-24 d-flex justify-content-between flex-wrap gap-2">
            <div className="text-16-400-20 color-title-navy font-poppins text-nowrap">
              My Membership
            </div>
          </div>
        </div>

        <div className="cps-24 cpe-21 cpt-24 cpb-24">
          <div className="row cmb-19">
            <div className="text-14-300-18 color-1717 col-md-5">
              Membership ID
            </div>
            <div className="text-14-500-18 color-1717 col-md-7">
              : {member_id}
            </div>
          </div>
          <div className="row">
            <div className="text-14-300-18 color-1717 col-md-5">
              Membership Plan
            </div>
            <div className="text-14-500-18 color-1717 col-md-7">
              : {plan_name}
            </div>
          </div>
        </div>
      </Card>

      <Card>
        <div className="bb cpb-16 cmb-18">
          <div className="cps-24 cpe-21 cpt-24 d-flex justify-content-between flex-wrap gap-2">
            <div className="text-16-400-20 color-title-navy font-poppins text-nowrap">
              My Benefits
            </div>
            {benefit_detail?.length > 3 && (
              <div
                className={`text-14-500-18 color-7cff pointer text-nowrap bottom-border"
            }`}
                onClick={() => {
                  navigate(`/${userFlag}/my-profile/my-membership`);
                }}
              >
                <span className="d-flex gap-2 align-items-center">
                  View All
                  <i className="bi bi-chevron-right" />
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="cps-24 cpe-21">
          <MembershipBenefits
            hidePlanDetail
            isNewBenefitsDesign
            limitedOptions={6}
            benefitDetail={benefit_detail}
          />
        </div>
      </Card>
    </>
  );
};

export default NewMembershipAndBenefits;
