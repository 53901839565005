import Card from "components/Layout/Card";
import React, { useEffect, useState } from "react";
import { getDataFromLocalStorage } from "utils/helpers";
import Events from ".";
import NewEventRequest from "./NewEventRequest";
import { getPendingRequestResources } from "store/slices";
import { useDispatch } from "react-redux";

const NewEvents = () => {
  const dispatch = useDispatch();
  const [childTabType, setChildTabType] = useState("1");
  const [resourceList, setResourceList] = useState([]);
  const userData = getDataFromLocalStorage();

  const fetchPendingRequestResources = async () => {
    const response = await dispatch(getPendingRequestResources());
    setResourceList(response?.data?.admin_requests);
  };

  const activeClass = "p-2 color-new-car text-14-500-18 active-tab-underline";
  const inActiveClass = "p-2 color-6866 text-16-500 text-14-500-18 pointer";

  useEffect(() => {
    if (childTabType === "2") {
      fetchPendingRequestResources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childTabType]);

  useEffect(() => {
    if (localStorage.prevType) {
      setChildTabType(localStorage.prevType);
      localStorage.removeItem("prevType");
    }
    // return()=>{
    //   localStorage.removeItem("prevType");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  

  return (
    <Card className="cps-20 cpe-20 cpb-20 cpt-20">
      <div className="d-flex align-items-center flex-wrap gap-2">
        <div
          className={childTabType === "1" ? activeClass : inActiveClass}
          onClick={() => {
            setChildTabType("1");
          }}
        >
          IFERP Events
        </div>
        {userData?.user_type === "0" && (
          <div
            className={childTabType === "2" ? activeClass : inActiveClass}
            onClick={() => {
              setChildTabType("2");
            }}
          >
            Pending Request
          </div>
        )}
        {userData?.user_type === "6" && (
          <div
            className={childTabType === "3" ? activeClass : inActiveClass}
            onClick={() => {
              setChildTabType("3");
            }}
          >
            Raised Request
          </div>
        )}
      </div>
      {childTabType === "1" && <Events />}
      {childTabType === "2" && (
        <NewEventRequest type={childTabType} resourceList={resourceList} />
      )}
      {childTabType === "3" && <NewEventRequest type={childTabType} />}
    </Card>
  );
};

export default NewEvents;
