import React from "react";
import "./DashboardJoinEvents.scss";
import { icons } from "utils/constants";

const DashboardJoinEvents = ({onClick}) => {
  return (
    <div className="dashboard-join-events-container d-flex align-items-center gap-2 pointer" onClick={onClick}>
      <img src={icons?.dashboard_live_event} alt="round" />
      Join Live Events
    </div>
  );
};

export default DashboardJoinEvents;
