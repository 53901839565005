import Card from "components/Layout/Card";
import React, { useState } from "react";
import AllProfiles from "./AllProfiles";
import PendingMembershipRequests from "./PendingMembershipRequests";

const PendingRequests = () => {
  const [childTabType, setChildTabType] = useState("1");

  const activeClass = "p-2 color-new-car text-14-500-18 active-tab-underline";
  const inActiveClass = "p-2 color-6866 text-16-500 text-14-500-18 pointer";
  return (
    <>
    <Card className="d-flex align-items-center p-1 unset-br mb-3">
      <div className="d-flex align-items-center flex-wrap gap-2">
        <div
          className={childTabType === "1" ? activeClass : inActiveClass}
          onClick={() => {
            setChildTabType("1");
          }}
        >
          All Pending Requests
        </div>
        <div
          className={childTabType === "0" ? activeClass : inActiveClass}
          onClick={() => {
            setChildTabType("0");
          }}
        >
          Membership Type Changes
        </div>
        {/* <div
          className={childTabType === "2" ? activeClass : inActiveClass}
          onClick={() => {
            setChildTabType("2");
          }}
        >
          Profile Changes
        </div> */}
      </div>
    </Card>
    {childTabType === "1" && <AllProfiles tabType={childTabType}  />}
    {childTabType === "0" && <PendingMembershipRequests />}
    </>
  );
};

export default PendingRequests;
