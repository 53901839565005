import React from "react";
import "./ToggleSwitch.scss";


const ToggleSwitch = ({
    id,
    name,
    // label,
    isChecked,
    value,
    onChange,
    disabled,
    // onClick,
  }) => {
  return (
    <div id="switch-container">
    <label className="switch">
      <input
        type="checkbox"
        value={value}
        id={id}
        name={name}
        checked={isChecked}
        onChange={onChange}
        // onClick={onClick}
        disabled={disabled}
      />
      <span className="slider round"></span>
    </label>
  </div>
  );
};

export default ToggleSwitch;
