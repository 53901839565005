import React, { useEffect, useState } from "react";
import Card from "components/Layout/Card";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchResearchProfileData,
  getDashboard,
  setIsTour,
  setPostID,
  setRProfileID,
} from "store/slices";
import { getDataFromLocalStorage } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import NewWelcome from "components/ReusableForms/Dashboard/NewWelcome";
import NewUpcomingEvents from "components/ReusableForms/Dashboard/NewDashboard/NewUpcomingEvents";
import DashboardPapersInfo from "../DashboardPapersInfo";
import NewJournals from "components/ReusableForms/Dashboard/NewDashboard/NewJournals";
import NewPeopleYouMayKnow from "components/ReusableForms/Dashboard/NewDashboard/NewPeopleYouMayKnow";
import NewMyGroups from "components/ReusableForms/Dashboard/NewDashboard/NewMyGroups";
import NewMembershipAndBenefits from "components/ReusableForms/Dashboard/NewDashboard/NewMembershipAndBenefits";
import NewCareerOppertunities from "components/ReusableForms/Dashboard/NewDashboard/NewCareerOppertunities";
import NewIFERPCounts from "components/ReusableForms/Dashboard/NewDashboard/NewIFERPCounts";
import NewPostList from "components/ReusableForms/Dashboard/NewDashboard/NewPostList";
import "./NewDashboard.scss";
import GlobalProfilePreviewPopup from "components/Layout/GlobalProfilePreviewPopup";

const NewDashboard = () => {
  const { userDetails, isGlobalProfilePreviewPopup } = useSelector((state) => ({
    userDetails: state.student.userDetails,
    isGlobalProfilePreviewPopup: state.global.isGlobalProfilePreviewPopup,
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(false);

  let { registration_status, tour_status, id } = getDataFromLocalStorage();

  const fetchDashboardData = async () => {
    const response = await dispatch(getDashboard(""));
    setDashboardData(response?.data || {});
  };

  const fetchDetails = async () => {
    setIsPageLoading(true);
    await dispatch(fetchResearchProfileData(`user_id=${id}`));
    setIsPageLoading(false);
  };

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDashboardData();
    if (registration_status === "4") {
      const emailRedirectURL = localStorage?.emailRedirectURL || "";
      if (tour_status === 0) {
        dispatch(setIsTour(true));
      } else if (emailRedirectURL) {
        navigate(`/professional/${emailRedirectURL}`);
        localStorage.removeItem("emailRedirectURL");
      } else if (localStorage?.openResearchProfile) {
        dispatch(setRProfileID(localStorage?.openResearchProfile));
        localStorage.removeItem("openResearchProfile");
        localStorage.isViewPublication = 1;
      } else if (localStorage?.openPostPopup) {
        dispatch(setPostID(localStorage?.openPostPopup));
        localStorage.removeItem("openPostPopup");
      } else {
        // Nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { paper_abstracts } = dashboardData || {};
  return (
    <div id="new-proffetional-dashboard" className="row">
      {isGlobalProfilePreviewPopup && (
        <GlobalProfilePreviewPopup
          isLoading={isPageLoading}
          fetchDetails={fetchDetails}
        />
      )}
      {/* counts */}
      <div className="col-md-12 mt-3">
        <NewIFERPCounts dashboardData={dashboardData} />
      </div>

      {/* welcome */}
      <div className="cmb-16">
        <div className="row">
          <div className="col-md-8 h-100">
            <Card>
              <NewWelcome
                researchProfileCount={userDetails?.completion_details}
              />
            </Card>
          </div>
          <div className="col-md-4">
            <Card className={"h-100"}>
              <NewUpcomingEvents />
            </Card>
          </div>
        </div>
      </div>

      {/*submitted paper */}
      <div className="cmb-16">
        <div className="row">
          <div className="col-md-8">
            <DashboardPapersInfo
              label={"Submitted Papers"}
              data={paper_abstracts}
            />
          </div>
          <div className="col-md-4">
            <Card className="h-100">
              <NewJournals />
            </Card>
          </div>
        </div>
      </div>

      {/*create post*/}
      <div className="cmb-16">
        <NewPostList />
      </div>

      {/* people know */}
      <div className="cmb-16">
        <div className="row">
          <div className="col-md-8">
            <Card className="">
              <NewPeopleYouMayKnow />
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="h-100">
              <NewMyGroups />
            </Card>
          </div>
        </div>
      </div>

      {/* membership & benefits */}
      <div className="cmb-16">
        <div className="row">
          <div className="col-md-8">
            <Card className="h-100">
              <NewCareerOppertunities />
            </Card>
          </div>
          <div className="col-md-4">
            <div className="">
              <NewMembershipAndBenefits />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDashboard;
