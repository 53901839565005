import Button from "components/form/Button";
import Dropdown from "components/form/Dropdown";
import Card from "components/Layout/Card";
import NewPostDetails from "components/Layout/CreatePost/NewPostDetails";
import Loader from "components/Layout/Loader";
import Profile from "components/Layout/Profile";
import ShareButton from "components/Layout/ShareButton";
import { filter, isEqual, omit } from "lodash";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  commentPost,
  editOrDeleteComment,
  fetchPost,
  saveUnsavePost,
  setPostID,
  setRProfileID,
  storePostList,
  usefulUnusefulPost,
} from "store/slices";
import { icons, limit, membershipType } from "utils/constants";
import {
  convertDescription,
  downloadFile,
  getDataFromLocalStorage,
  messageTime,
  objectToFormData,
  titleCaseString,
  trimLeftSpace,
} from "utils/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

// const type = "discover-posts"
const NewPostList = () => {
  const { postCategoryList, newPostList } = useSelector((state) => ({
    postCategoryList: state.global.postCategoryList,
    newPostList: state.global.postList,
  }));
  const commentRef = useRef();
  const userData = getDataFromLocalStorage();
  const { id: loginUserID, user_type: userType } = userData;
  const findType = membershipType.find((o) => o.id === userType);
  let moduleType = "dashboard";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postCategory, setPostCategory] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [isNext, setIsNext] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [elRefs, setElRefs] = useState([]);
  const [saveLoader, setSaveLoader] = useState("");
  const [playIndex, setPlayIndex] = useState("");
  // const [slides, setSlides] = useState([]);
  const [commentData, setCommentData] = useState({
    comment: "",
    isComment: "",
    isCommentLoading: false,
    oldComment: "",
    deleteID: "",
    postID: "",
    commentID: "",
  });
  const [filterData, setFilterData] = useState({
    search: "",
    filter: "",
  });
  const [paginate, setPaginate] = useState({
    offset: 0,
    limit: limit,
    total: 0,
  });
  const handelUsefulUnusefulPost = async (postID) => {
    const response = await dispatch(
      usefulUnusefulPost(objectToFormData({ post_id: postID }))
    );
    if (response?.status === 200) {
      let oldPostList = newPostList;
      oldPostList = oldPostList.map((obj) => {
        let copy = { ...obj };
        if (obj.id === postID) {
          const likesStatus = copy.is_liked === 0 ? 1 : 0;
          let count = copy.total_likes;
          count = likesStatus === 0 ? count - 1 : count + 1;
          copy = {
            ...copy,
            is_liked: likesStatus,
            total_likes: count <= 0 ? 0 : count,
          };
        }
        return copy;
      });
      dispatch(storePostList(oldPostList));
    }
  };

  const handelSaveUnsave = async (postID) => {
    setSaveLoader(postID);
    const response = await dispatch(
      saveUnsavePost(objectToFormData({ post_id: postID }))
    );
    if (response?.status === 200) {
      let oldPostList = newPostList;
      oldPostList = oldPostList.map((obj) => {
        let copy = { ...obj };
        if (obj.id === postID) {
          copy = {
            ...copy,
            is_saved: copy.is_saved === 0 ? 1 : 0,
          };
        }
        return copy;
      });
      dispatch(storePostList(oldPostList));
    }
    setSaveLoader("");
  };

  const handelDeleteComment = async (postID, commentID) => {
    setCommentData({ ...commentData, deleteID: commentID, postID: postID });
    const response = await dispatch(
      editOrDeleteComment(
        objectToFormData({ post_id: postID, id: commentID, key: "delete" })
      )
    );
    if (response?.status === 200) {
      let oldPostList = newPostList;
      oldPostList = await oldPostList.map((obj) => {
        let copy = { ...obj };
        if (obj.id === postID) {
          const count = obj.total_comments - 1;
          copy = {
            ...copy,
            total_comments: count <= 0 ? 0 : count,
            comments: obj.comments.filter((o) => o.id !== commentID),
          };
        }
        return copy;
      });
      dispatch(storePostList(oldPostList));
    }

    setCommentData({ ...commentData, deleteID: "", postID: "" });
  };

  const handelUpdateComment = async () => {
    setCommentData({ ...commentData, isCommentLoading: true });
    const response = await dispatch(
      editOrDeleteComment(
        objectToFormData({
          comment: commentData?.comment,
          post_id: commentData?.postID,
          id: commentData?.commentID,
          key: "edit",
        })
      )
    );
    if (response?.status === 200) {
      let oldPostList = newPostList;
      oldPostList = await oldPostList.map((obj) => {
        let copy = { ...obj };
        if (obj.id === commentData.postID) {
          copy = {
            ...copy,
            comments: obj.comments.map((cObj) => {
              let cCopy = { ...cObj };
              if (cObj.id === commentData.commentID) {
                cCopy = {
                  ...cCopy,
                  comment: commentData.comment,
                };
              }
              return cCopy;
            }),
          };
        }
        return copy;
      });
      dispatch(storePostList(oldPostList));
    }

    setCommentData({
      ...commentData,
      commentID: "",
      postID: "",
      oldComment: "",
      comment: "",
      isCommentLoading: false,
    });
  };

  const handelCommentPost = async (postID) => {
    setCommentData({ ...commentData, isCommentLoading: true });
    const response = await dispatch(
      commentPost(
        objectToFormData({ comment: commentData?.comment, post_id: postID })
      )
    );
    if (response?.status === 200) {
      let oldPostList = newPostList;
      oldPostList = oldPostList.map((obj) => {
        let copy = { ...obj };
        if (obj.id === postID) {
          copy = {
            ...copy,
            total_comments: obj.total_comments + 1,
            comments: [...obj?.comments, response?.data],
          };
        }
        return copy;
      });
      dispatch(storePostList(oldPostList));
      setTimeout(() => {
        if (commentRef?.current) {
          commentRef.current.lastChild?.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
    setCommentData({ ...commentData, isCommentLoading: false, comment: "" });
  };

  const handlePagination = (offset) => {
    setIsLoading(true);
    const newData = { ...paginate, ...filterData, offset: offset };
    getPost(newData);
    setIsLoading(false);
  };

  const getPost = async (obj) => {
    const payload = omit(obj, ["total"]);
    const newType = "discover";
    const res = await dispatch(fetchPost(newType, payload));
    setPaginate({
      ...obj,
      total: res?.data?.count || 0,
    });
    let newData = [];
    res?.data?.postList?.forEach((_index) => {
      newData.push(createRef());
    });

    setElRefs(newData);
    setIsLoading(false);
  };

  useEffect(() => {
    const resetPagination = {
      limit: limit,
      offset: 0,
      total: 0,
      filterList: null,
    };
    setPaginate(resetPagination);
    setFilterData({ ...filterData, search: "", filter: "" });
    setIsLoading(true);

    setCommentData({
      ...commentData,
      comment: "",
      oldComment: "",
      isComment: "",
      commentID: "",
      postID: "",
      isCommentLoading: false,
    });
    getPost({
      ...resetPagination,
      ...filterData,
      filterList: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (commentData?.isComment) {
      setTimeout(() => {
        if (commentRef?.current) {
          commentRef.current.lastChild?.scrollIntoView();
        }
      }, 100);
    }
  }, [commentData?.isComment]);

  const navigateToPost = () => {
    navigate(`/${userType}/network-management/network/posts/discover-posts`);
  };

  const { comment, isComment, isCommentLoading, postID, deleteID, oldComment } =
    commentData;

  const displayList = useMemo(() => {
    return newPostList && newPostList?.length > 0
      ? filter(newPostList, { is_hidden: 0 })
      : [];
  }, [newPostList]);

 

  const activePage = paginate?.offset / paginate?.limit + 1;
  const totalPage = Math.ceil(paginate?.total / paginate?.limit);

  
  return (
    <Card className="cps-24 cpe-21 cpt-32 cpb-32 position-relative">
      {isNext && (
        <NewPostDetails
          //   type={type}
          postCategory={postCategory}
          postTitle={postTitle}
          onHide={() => {
            setIsNext(false);
            setPostCategory("");
            setPostTitle("");
          }}
          handelSuccess={() => {
            setIsNext(false);
            setPostCategory("");
            setPostTitle("");
            // if (successParent) {
            //   successParent();
            // }
          }}
        />
      )}
      <div className="d-flex align-items-center justify-content-between cmb-32 cpe-21">
        <div
          className="text-16-400-20 color-title-navy font-poppins"
          id="recent-posts-id"
        >
          Recent Posts
        </div>
        <div className="d-flex align-items-center justify-content-end gap-2 flex-grow-1">
          <div className="text-16-400-20 color-title-navy">Create Post:</div>
          <div className="row">
            <div className="col-md-4">
              <Dropdown
                id="eventid"
                placeholder="Select post category*"
                value={postCategory}
                options={postCategoryList}
                optionValue="name"
                onChange={(e) => {
                  setPostCategory(e.target.value);
                }}
              />
            </div>
            <div className="col-md-7 post-input-container">
              <input
                id="post-input"
                placeholder="Post Title*"
                value={postTitle}
                onChange={(e) => {
                  setPostTitle(trimLeftSpace(e.target.value));
                }}
              />
            </div>
            <div className="col-md-1 align-items-center">
              <Button
                className="h-35 w-auto color-white cmt-5"
                btnStyle="primary-dark"
                icon={<i className="bi bi-chevron-right" />}
                disabled={!postCategory || !postTitle}
                onClick={() => {
                  setIsNext(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="center-flex cpt-50 cpb-50">
          <Loader size="md" />
        </div>
      ) : displayList?.length === 0 ? (
        <div className="center-flex cpt-50 cpb-50">No Post Found</div>
      ) : (
        <>
          <Swiper
            lazy={"true"}
            pagination={{
              clickable: true,
              el: ".swiper-pagination-bullets",
            }}
            modules={[Pagination]}
            allowSlidePrev={true}
            // grabCursor={true}
            slidesPerView={"auto"}
            spaceBetween={10}
            loop={true}
            className="mySwiper"
          >
            {displayList?.slice(0, 5)?.map((elem, index) => {
              const {
                id,
                user_details,
                title,
                post,
                nPost,
                nPostSize,
                created_date,
                is_liked,
                is_saved,
                total_likes,
                total_comments,
                comments,
                category_name,
                sub_category_name,
                description,
                abstract,
                co_authors,
                author_details,
                presentation_link,
                nPresentationLink,
                nThumbnail,
              } = elem;
              let hURL = `${window.location.origin}/${findType?.type}/${moduleType}/network/post/post-details/${id}`;
              let about_article = "";
              if (abstract) {
                let text = abstract || "";
                text = convertDescription(text, hURL);
                about_article = text;
              }
              const {
                id: userID,
                name,
                state,
                country,
                profile_photo,
                user_type,
              } = user_details;
              let authorName = "";
              if (author_details?.user_id === userID) {
                authorName = name;
              } else {
                authorName =
                  author_details?.is_ownership === "1"
                    ? author_details?.name
                    : "";
              }
              const isReasearchProfile = ["2", "5"].includes(user_type);
              const isAuthorReasearchProfile = ["2", "5"].includes(
                author_details?.user_type
              );

              let isAnyCoAuthor = co_authors?.some((o) => o.is_ownership);

              const postType = post ? post?.split(".")?.pop() : "";
              const fileName = post ? post?.split("/")?.pop() : "";
              const pendingVerify = [];
              if (
                author_details?.is_ownership === "0" &&
                author_details?.user_id
              ) {
                pendingVerify.push(author_details?.user_id);
              }
              co_authors?.forEach((cElm) => {
                if (!cElm?.is_ownership) {
                  pendingVerify.push(cElm.id);
                }
              });

              return (
                <SwiperSlide key={index}>
                  <div className="dashboard-post-list-border">
                    <div className="cps-19 cpt-24 cpe-29">
                      <div className="row cmb-19">
                        {(presentation_link ||
                          description ||
                          about_article) && (
                          <div className="col-md-6">
                           {presentation_link&& <div className="post-image-video cmb-20">
                              {presentation_link && (
                                <div className="h-100">
                                  <div className="position-relative h-100">
                                    <video
                                      controls={playIndex === index}
                                      ref={elRefs[index]}
                                      width="100%"
                                      poster={nThumbnail || ""}
                                      preload="none"
                                      style={{
                                        background: `transparent url(${nThumbnail}) 50% 50% / cover no-repeat`,
                                        // marginTop: "20px",
                                        height: "100%",
                                        borderRadius: "10px",
                                        objectFit: "cover",
                                      }}
                                    >
                                      <source
                                        src={nPresentationLink}
                                        type="video/mp4"
                                      />
                                    </video>
                                    {playIndex !== index && (
                                      <div
                                        className="btn-vd-play"
                                        onClick={() => {
                                          if (playIndex !== "") {
                                            elRefs[playIndex]?.current?.pause();
                                          }
                                          elRefs[index]?.current?.play();
                                          setPlayIndex(index);
                                        }}
                                      >
                                        <i className="bi bi-play-fill" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>}
                            {(description || about_article) && (
                              <>
                                <div className="text-14-400-18 color-1717 cmb-12">
                                  Abstract/Description :
                                </div>
                                {description && (
                                  <div
                                    className="text-14-400-24 color-black-olive two-line-elipses"
                                    dangerouslySetInnerHTML={{
                                      __html: description,
                                    }}
                                  />
                                )}
                                {about_article && (
                                  <div
                                    className="text-14-400-24 color-black-olive two-line-elipses"
                                    dangerouslySetInnerHTML={{
                                      __html: about_article,
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        )}
                        <div
                          className={`${
                            presentation_link || description || about_article
                              ? "col-md-6"
                              : "col-md-12"
                          }`}
                        >
                          <div className="text-16-500-27 color-new-car cmb-24">
                            {titleCaseString(title)}
                          </div>
                          <div className="d-flex align-items-center justify-content-between cmb-24">
                            <Button
                              text={sub_category_name || category_name}
                              btnStyle="primary-light"
                              className="h-auto text-14-400"
                              isSquare
                            />
                            <div className="text-12-400-15 color-6866">
                              {messageTime(created_date)}
                            </div>
                          </div>
                          <div className="d-flex align-items-center left-block cmb-24">
                            <Profile
                              isRounded
                              isS3UserURL
                              text={name}
                              size="s-40"
                              url={profile_photo}
                            />
                            <div className="user-details-block ms-3">
                              <div
                                className={`text-14-400-18 cmb-6 ${
                                  isReasearchProfile
                                    ? "color-new-car pointer hover-effect"
                                    : "color-raisin-black"
                                }`}
                                onClick={() => {
                                  if (isReasearchProfile) {
                                    dispatch(setRProfileID(userID));
                                  }
                                }}
                              >
                                {name}
                              </div>
                              <div className="text-12-400-15 color-6866">
                                {`${(state || "") && (country || "")}`}
                              </div>
                            </div>
                          </div>

                          {authorName && (
                            <div className="d-flex align-items-center gap-3 cmb-24">
                              <div className="text-14-400-18 color-1717">
                                Author:
                              </div>
                              <div
                                className={`text-14-300-18 ${
                                  isAuthorReasearchProfile
                                    ? "color-new-car pointer hover-effect"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (isAuthorReasearchProfile) {
                                    dispatch(
                                      setRProfileID(author_details?.user_id)
                                    );
                                  }
                                }}
                              >
                                {authorName}
                              </div>
                            </div>
                          )}

                          {co_authors?.length > 0 && isAnyCoAuthor && (
                            <div className="d-flex align-items-center gap-3 cmb-24">
                              <div className="text-14-400-18 color-1717">
                                Co Author:
                              </div>
                              {co_authors?.map((el, i) => {
                                return (
                                  <div
                                    className={`text-14-300-18 d-flex${
                                      isReasearchProfile
                                        ? "color-new-car pointer hover-effect"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      if (isReasearchProfile) {
                                        dispatch(setRProfileID(el?.id));
                                      }
                                    }}
                                    key={i}
                                  >
                                    {titleCaseString(el?.name)}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {post &&
                            ["pdf", "doc", "docx", "csv", "html"].includes(
                              postType
                            ) && (
                              <>
                                <div className="text-14-400-18 color-1717 cmb-12">
                                  Full-Text Paper:
                                </div>
                                <div
                                  className="d-flex justify-content-between align-items-center flex-wrap bg-f6ff cpt-10 cpe-14 cpb-10 cps-14 rounded br-10 gap-2 pointer"
                                  onClick={() => {
                                    dispatch(setPostID(id));
                                  }}
                                >
                                  <div className="d-flex flex-wrap gap-3">
                                    <div className="post-file">
                                      <i className="bi bi-file-earmark" />
                                    </div>
                                    <div>
                                      <div className="text-14-400-18 color-1717">
                                        {titleCaseString(fileName)}
                                      </div>
                                      {nPostSize && (
                                        <div className="text-12-300-15 color-black-olive">
                                          {`${nPostSize} `}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <Button
                                      icon={
                                        <i className="bi bi-download text-12-500-15 me-2" />
                                      }
                                      className={"text-12-500-15 h-30"}
                                      text="Download"
                                      btnStyle="primary-dark"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        dispatch(downloadFile(nPost));
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                          {/* <div
                              className="mb-3 img-block pointer"
                              onClick={() => {
                                dispatch(setPostID(id));
                              }}
                            >
                              <img src={nPost} alt="post" />
                            </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="bottom-block">
                      <div
                        className="pointer"
                        onClick={() => {
                          handelUsefulUnusefulPost(id);
                        }}
                      >
                        <i
                          className={`color-new-car me-2 pointer bi bi-hand-thumbs-up${
                            is_liked ? "-fill" : ""
                          }`}
                        />
                        {total_likes !== 0 && (
                          <span className="test-14-500-17 color-title-navy me-2">
                            <b>{total_likes}</b>
                          </span>
                        )}
                        <span className="test-14-400-17 color-subtitle-navy">
                          Useful
                        </span>
                      </div>
                      <div
                        className="pointer text-nowrap"
                        onClick={() => {
                          setCommentData({
                            ...commentData,
                            comment: "",
                            isComment: id,
                          });
                        }}
                      >
                        <i className="bi bi-chat color-new-car me-2 pointer" />
                        {total_comments !== 0 && (
                          <span className="test-14-500-17 color-title-navy me-2">
                            <b>{total_comments}</b>
                          </span>
                        )}
                        <span className="test-14-400-17 color-subtitle-navy">
                          Comments
                        </span>
                      </div>
                      <div className="">
                        <ShareButton
                          type="POST"
                          // url={`${window?.location?.origin}/${findType?.type}/${moduleType}/network/post/post-details/${id}`}
                          url={`${window?.location?.origin}/member/global-post/${id}`}
                          title={`hey, kindly Check ${
                            sub_category_name || category_name
                          } on ${title}`}
                          postID={id}
                        />
                      </div>
                      <div
                        className="pointer"
                        onClick={() => {
                          if (!saveLoader) {
                            handelSaveUnsave(id);
                          }
                        }}
                      >
                        <span>
                          <i
                            className={`color-new-car pointer bi bi-plus-square${
                              is_saved ? "-fill" : ""
                            }`}
                          />
                        </span>
                        <span className="test-14-400-17 color-subtitle-navy ms-2">
                          {saveLoader === id ? (
                            <Loader size="sm" />
                          ) : is_saved ? (
                            "Unsave"
                          ) : (
                            "Save"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {id === isComment && (
                    <Card className="dashboard-post-list-border post-block cps-18 cpe-18 cpt-18 cpb-18 unset-br cmt-20">
                      <div className="d-flex justify-content-end cmb-20">
                        <i
                          className="bi bi-x modal-close-button pointer text-20-500"
                          onClick={() => {
                            setCommentData({
                              ...commentData,
                              isComment: "",
                              oldComment: "",
                            });
                          }}
                        />
                      </div>
                      {comments && (
                        <div
                          className="comment-scroll iferp-scroll"
                          id="post-comment-list"
                          ref={commentRef}
                        >
                          {comments?.map((cElem, cIndex) => {
                            const { comment } = cElem;
                            const {
                              name: cName,
                              id: cUserId,
                              profile_photo: cProfilePhoto,
                            } = cElem?.user_details || {};
                            const isDeleteComment =
                              postID === id && cElem.id === deleteID;
                            const isEdit = loginUserID === cUserId;
                            const isDelete = loginUserID === cUserId;
                            return (
                              <div
                                key={cIndex}
                                className="shadow-sm p-3 cmb-10 bg-white"
                              >
                                <div className="d-flex align-items-center">
                                  <Profile
                                    isRounded
                                    isS3UserURL
                                    text={cName}
                                    size="s-34"
                                    url={cProfilePhoto}
                                  />
                                  <div className="ms-2 text-18-400">
                                    {cName && titleCaseString(cName)}
                                  </div>
                                  <div className="flex-grow-1 d-flex justify-content-end text-18-400 color-black-olive gap-3">
                                    {isEdit && (
                                      <div
                                        className="d-flex pointer"
                                        onClick={() => {
                                          setCommentData({
                                            ...commentData,
                                            oldComment: comment,
                                            comment: comment,
                                            commentID: cElem.id,
                                            postID: id,
                                          });
                                        }}
                                      >
                                        <i className="bi bi-pencil-square" />
                                      </div>
                                    )}
                                    {isDelete && (
                                      <div
                                        className="d-flex pointer"
                                        onClick={() => {
                                          handelDeleteComment(id, cElem.id);
                                        }}
                                      >
                                        {isDeleteComment ? (
                                          <Loader size="sm" />
                                        ) : (
                                          <i className="bi bi-trash" />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="cms-40">{comment}</div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div className="post-input-container">
                        <input
                          id="post-input"
                          className="cpe-10"
                          placeholder="Enter comment"
                          value={comment}
                          onKeyUp={(e) => {
                            if (e?.keyCode === 13) {
                              if (oldComment) {
                                handelUpdateComment();
                              } else {
                                handelCommentPost(id);
                              }
                            }
                            if (e?.keyCode === 27) {
                              setCommentData({
                                ...commentData,
                                comment: "",
                                oldComment: "",
                              });
                            }
                          }}
                          onChange={(e) => {
                            setCommentData({
                              ...commentData,
                              comment: trimLeftSpace(e.target.value),
                            });
                          }}
                        />
                        <div className="create-post-icon-container">
                          <div className="create-post-icon-container">
                            <Button
                              className="h-35"
                              btnStyle="primary-dark"
                              text="Comment"
                              disabled={
                                oldComment
                                  ? isEqual(oldComment, comment)
                                  : !comment
                              }
                              btnLoading={isCommentLoading}
                              onClick={() => {
                                if (oldComment) {
                                  handelUpdateComment();
                                } else {
                                  handelCommentPost(id);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      )}
      {!isLoading && displayList?.length > 0 && (
        <div className="cmt-50 d-flex justify-content-center custom-pagination-block">
          <div className="d-flex custom-pagination">
            <span
              className={`prev ${activePage === 1 ? "" : "active"}`}
              onClick={() => {
                if (activePage !== 1) {
                  handlePagination(paginate?.offset - paginate?.limit);
                }
              }}
            >
              <img
                src={icons.paginationCircleLeft}
                alt="left"
                className={activePage === 1 ? "" : "active-img"}
              />
            </span>
            <div className="swiper-pagination-bullets" />
            <span
              className={`next ${activePage === totalPage ? "" : "active"}`}
              onClick={() => {
                if (activePage !== totalPage) {
                  handlePagination(paginate?.offset + paginate?.limit);
                }
              }}
            >
              <img
                src={icons.paginationCircleRight}
                alt="right"
                className={activePage === totalPage ? "" : "active-img"}
              />
            </span>
          </div>

          <div
            className="cpb-19 text-14-500-18 color-7cff view-all-post d-flex align-item-center justify-content-end gap-2"
            onClick={() => {
              navigateToPost();
            }}
          >
            View All Posts
            <i className="bi bi-chevron-right" />
          </div>
        </div>
      )}
    </Card>
  );
};

export default NewPostList;
