import Button from "components/form/Button";
import TextInput from "components/form/TextInput";
import ToggleSwitch from "components/form/ToggleSwitch";
import Loader from "components/Layout/Loader";
import { Formik } from "formik";
import { isEmpty, isEqual } from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  throwError,
  throwSuccess,
  updateEventPriceCategories,
} from "store/slices";
import { objectToFormData, titleCaseString } from "utils/helpers";

const PriceCategory = ({ isLoading, priceCategoriesList, handleSuccess }) => {
  const dispatch = useDispatch();

  const [newPriceCategories, setNewPriceCategories] = useState([]);
  const [isEventLoading, setIsEventLoading] = useState(false);
  const initialValues = priceCategoriesList?.reduce((acc, item, index) => {
    acc[`name${index}`] = item?.name || "";
    acc[`status${index}`] = item?.status === "1";
    return acc;
  }, {});

  const isFormValid = (values) => {
    return (
      // Check if initial values and current values are the same or if any new category field is invalid
      isEqual(initialValues, values) ||
      newPriceCategories?.some(
        (_, index) =>
          isEmpty(values[`newName${index}`]) ||
          values[`newStatus${index}`] === undefined
      )
    );
  };

  const handleAddPriceCategory = (setFieldValue) => {
    const newCategory = { id: "", name: "", status: false };
    setNewPriceCategories((prev) => [...prev, newCategory]);
    setFieldValue(`newName${newPriceCategories?.length}`, "");
    setFieldValue(`newStatus${newPriceCategories?.length}`, false);
  };

  const handleUpdateEventCategory = async (value) => {
    setIsEventLoading(true);
    const updatedCategories = priceCategoriesList?.map((item, index) => ({
      id: item?.id || "",
      name: titleCaseString(value[`name${index}`]),
      status: value[`status${index}`] ? "1" : "0",
    }));

    const newFormattedCategories = newPriceCategories?.map((_, newIndex) => ({
      id: "",
      name: titleCaseString(value[`newName${newIndex}`]),
      status: value[`newStatus${newIndex}`] ? "1" : "0",
    }));

    const payload = [...updatedCategories, ...newFormattedCategories];
    const newPayload = {
      categories: JSON.stringify(payload),
      category_type: "2",
    };

    const response = await dispatch(
      updateEventPriceCategories(objectToFormData(newPayload))
    );

    if (response?.status === 200) {
      setNewPriceCategories([]);
      dispatch(throwSuccess(response?.message));
      handleSuccess();
    } else {
      dispatch(throwError({ message: response?.message }));
    }

    setIsEventLoading(false);
  };
  return isLoading ? (
    <div className="center-flex cpt-50 cpb-50">
      <Loader size="md" />
    </div>
  ) : (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleUpdateEventCategory}
    >
      {(props) => {
        const { values, handleChange, setFieldValue, handleSubmit } = props;
        return (
          <form>
            <div className="row cmb-24">
              {priceCategoriesList?.map((_, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="col-md-4 cmb-24">
                      <TextInput
                        label={`Registration Category ${i + 1}`}
                        id={`name${i}`}
                        onChange={handleChange}
                        value={values[`name${i}`]}
                        disabled={!values[`status${i}`]}
                      />
                    </div>
                    <div className="col-md-2 cmb-24">
                      <div className="d-flex align-items-center h-100 cpt-25">
                        <ToggleSwitch
                          isChecked={values[`status${i}`]}
                          onChange={(e) =>
                            setFieldValue(`status${i}`, e.target.checked)
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}

              {newPriceCategories?.map((_, index) => {
                const finalIndex = priceCategoriesList?.length + index + 1;

                return (
                  <React.Fragment key={`new-${index}`}>
                    <div className="col-md-4">
                      <TextInput
                        label={`New Registration Category ${finalIndex}`}
                        id={`newName${index}`}
                        onChange={handleChange}
                        value={values[`newName${index}`]}
                      />
                    </div>
                    <div className="col-md-2">
                      <div className="d-flex align-items-center h-100 cpt-25">
                        <ToggleSwitch
                          isChecked={values[`newStatus${index}`]}
                          onChange={(e) =>
                            setFieldValue(`newStatus${index}`, e.target.checked)
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

            <div className="d-flex align-items-center gap-3">
              <Button
                text={"Update"}
                btnStyle={"primary-dark"}
                className={"h-35 cps-15 cpe-12"}
                onClick={handleSubmit}
                btnLoading={isEventLoading}
                disabled={isFormValid(values)}
              />
              <Button
                text={" + Add Category"}
                btnStyle={"primary-outline"}
                className={"h-35 cps-15 cpe-12"}
                onClick={() => {
                  handleAddPriceCategory(setFieldValue);
                }}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default PriceCategory;
