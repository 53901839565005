import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import TextInput from "components/form/TextInput";
import Button from "components/form/Button";
import { icons } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchConferencesList,
  setAttendancePayload,
  setIsAttendanceConferencePopup,
  setIsAttendanceConfirmationPopup,
  throwError,
} from "store/slices";
import { QrReader } from "react-qr-reader";
import { decrypt, objectToQueryParams } from "utils/helpers";
import "./AttendanceConferencePopup.scss";
import Dropdown from "components/form/Dropdown";

const AttendanceConferencePopup = () => {
  const dispatch = useDispatch();
  const [textInputValue, setTextInputValue] = useState("");
  const [showScanner, setShowScanner] = useState(false);
  const [isScanningFailed, setIsScanningFailed] = useState(false);
  const [hasCameraPermission, setHasCameraPermission] = useState(null);
  const { conferencesList } = useSelector((state) => ({
    conferencesList: state.global.conferencesList,
  }));
  const [conferenceEvent, setConferenceEvent] = useState("");

  const getConferenceList = async () => {
    await dispatch(fetchConferencesList(objectToQueryParams({event_type:"upcoming"})));
  };

  const handleScan = (data) => {
    if (data) {
      setShowScanner(false);
      setIsScanningFailed(false);
    }
  };

  const handleError = (err) => {
    setIsScanningFailed(true);
    setShowScanner(false);
  };

  const checkCameraPermission = async () => {
    try {
      const stream = await navigator?.mediaDevices?.getUserMedia({
        video: true,
      });
      stream?.getTracks()?.forEach((track) => track?.stop()); // Stop the track after checking
      setHasCameraPermission(true);
    } catch (err) {
      setHasCameraPermission(false);
    }
  };

  useEffect(() => {
    if (navigator?.mediaDevices && navigator?.mediaDevices?.getUserMedia) {
      checkCameraPermission();
    } else {
      setHasCameraPermission(false);
    }
  }, []);

  useEffect(() => {
    getConferenceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const constraints = {
    video: {
      facingMode: "environment",
      width: { ideal: 1280 },
      height: { ideal: 720 },
    },
  };
  
  return (
    <Modal
      onHide={() => {
        dispatch(setIsAttendanceConferencePopup(false));
      }}
      size="md"
      title={
        showScanner ? (
          "Scan QR Code"
        ) : isScanningFailed ? (
          <img src={icons?.circleInfo} alt="info" />
        ) : (
          "Conference Attendance"
        )
      }
    >
      {showScanner ? (
        <>
          <div className="d-flex align-items-center justify-content-center">
            <p className="text-16-300-20 color-black-olive">
              Please QR Code Inside the frame
            </p>
          </div>
          <div className="qr-scanner-container">
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              constraints={constraints}
              onResult={(result, error) => {
                if (result) {
                  // "http://localhost:3000/member/login?event_id=Yr2aKedr21zA2yqOLE9fZA==&user_id=zruxbi9U29GS+L2OqJ1S2A==";
                  const queryParams = new URLSearchParams(
                    new URL(result?.text)?.search
                  );
                  const encodedEventId = queryParams.get("event_id");
                  const encodedUserId = queryParams.get("user_id");
                  const formattedUserId = encodedUserId?.includes(" ")
                    ? encodedUserId?.replace(/ /g, "+")
                    : encodedUserId;

                  const event_id = encodedEventId
                    ? decrypt(encodedEventId)
                    : false;
                  const user_id = encodedUserId
                    ? decrypt(formattedUserId)
                    : false;
                  // Redirect to the scanned URL
                  if (event_id && user_id) {
                    dispatch(
                      setAttendancePayload({
                        event_id: event_id,
                        user_id: user_id,
                        attendance_type: 1,
                      })
                    );
                    setShowScanner(false);
                    dispatch(setIsAttendanceConferencePopup(false));
                    dispatch(setIsAttendanceConfirmationPopup(true));
                  } else {
                    dispatch(throwError({ message: "Please scan valid QR" }));
                    setShowScanner(false);
                  }
                }

                if (error) {
                  console.log("ee", error);
                }
              }}
              style={{ width: "100%", height: "300px" }}
            />
            <div className="text-center text-16-300-20 color-black-olive">
              Scanning the QR Code...
            </div>
            <Button
              btnStyle={"unset-primary"}
              text="Cancel"
              onClick={() => setShowScanner(false)}
            />
          </div>
        </>
      ) : (
        <>
          <div className="attendance-conference-popup-container d-flex flex-column align-items-center cps-25 cpe-25">
            <p className="text-16-300-20 color-black-olive cmt-20 cmb-40">
              {isScanningFailed
                ? "Unable to verify the QR Code"
                : "Please choose any one option to mark the attendance"}
            </p>
            <div
              className="cmb-23 d-flex align-items-center gap-2 scan-button pointer"
              onClick={() => {
                if (hasCameraPermission === true) {
                  setShowScanner(true);
                } else {
                  setIsScanningFailed(true); // Set error state if permission is not granted
                }
              }}
            >
              <img src={icons?.primaryScanner} alt="scanner" />
              <span className="text-20-400-25 color-new-car">{`Scan QR ${
                isScanningFailed ? "Again" : ""
              }`}</span>
            </div>
            <div className="cmb-23">Or</div>
          </div>
          <div className="cmb-20 cps-25 cpe-25">
            <Dropdown
              id="id"
              label="Event"
              placeholder="Select Event"
              value={conferenceEvent}
              options={conferencesList}
              optionValue="event_name"
              onChange={(e) => setConferenceEvent(e.target.value)}
            />
          </div>
          <div className="cmb-20 cps-25 cpe-25">
            <TextInput
              label="Phone number/ Membership id/ Paper id/ Email id"
              placeholder="Enter Phone No."
              value={textInputValue}
              id={"textInputValue"}
              onChange={(e) => {
                setTextInputValue(e.target.value);
              }}
            />
          </div>

          <div className="cps-25 cpe-25 cmb-30">
            <Button
              btnStyle={"primary-dark"}
              text="Mark Attendance"
              onClick={() => {
                dispatch(
                  setAttendancePayload({
                    event_id: conferenceEvent,
                    common_input_value: textInputValue,
                    attendance_type: 2,
                  })
                );
                dispatch(setIsAttendanceConfirmationPopup(true));
                dispatch(setIsAttendanceConferencePopup(false));
              }}
              disabled={!textInputValue || !conferenceEvent}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default AttendanceConferencePopup;
