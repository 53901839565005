import Button from "components/form/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { icons, membershipType } from "utils/constants";
import {
  getDataFromLocalStorage,
  getUserType,
  objectToFormData,
} from "utils/helpers";
import VerifyModel from "./VerifyModel";
import { useDispatch } from "react-redux";
import {
  handelSpeakerEmailVerify,
  setIsGlobalProfilePreviewPopup,
} from "store/slices";
import DashboardJoinEvents from "../DashboardJoinEvents";

const perks = [
  {
    id: 1,
    name: "Learn",
    icons: icons?.NewBook,
  },
  {
    id: 2,
    name: "Present",
    icons: icons?.NewComputer,
  },
  {
    id: 1,
    name: "Publish",
    icons: icons?.NewPresentation,
  },
  {
    id: 1,
    name: "Network",
    icons: icons?.NewPeople,
  },
  {
    id: 1,
    name: "Mentor",
    icons: icons?.Newlocal_library,
  },
  {
    id: 1,
    name: "Jobs",
    icons: icons?.NewBuildings,
  },
];

const Welcome = ({ researchProfileCount = 50 }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [showEmailModel, setShowEmailModel] = useState(false);
  let {
    first_name,
    last_name,
    user_type,
    name,
    email_id,
    registration_email_otp_status,
  } = getDataFromLocalStorage();
  const findType = membershipType.find((o) => o.id === user_type)?.type;

  const handleRedirect = (blockType) => {
    const userType = getUserType();
    const blockRoute = {
      Learn: `/${userType}/digital-library`,
      Present: `/${userType}/conferences-and-events`,
      Publish: `/${userType}/publications`,
      Network: `/${userType}/network-management/network`,
      Mentor: `/${userType}/mentorship/mentee/all-mentors`,
      Jobs: `/${userType}/career-support`,
    };
    navigate(blockRoute[blockType]);
  };

  const getVerifiyedEmail = async () => {
    const response = await dispatch(
      handelSpeakerEmailVerify(objectToFormData({ email: email_id }))
    );
    // setModalShow(false);
    if (response?.status === 200) {
      // const response = await dispatch(fetchUserDetails());
      setModalShow(false);
    }
  };

  const remainPercentage = 100 - researchProfileCount;
  return (
    <>
      <div className="cpt-30 cpb-40 cps-24 cpe-21 d-flex flex-wrap h-100">
        <div className="d-flex justify-content-center flex-column">
          <div className="d-flex align-items-center gap-2 cmb-21">
            <div className="font-poppins text-20-400-25 color-title-navy">
              Hello, {name ? `${name}` : `${first_name} ${last_name}`} !
            </div>
            <DashboardJoinEvents onClick={()=>{
              navigate(`/${findType}/dashboard/live-events`);
            }}/>
          </div>
          {["2", "5"]?.includes(user_type) && researchProfileCount && (
            <div
              className={`${
                registration_email_otp_status === "0" ? "" : "cmb-24"
              } d-flex justify-content-between cmb-11 gap-3`}
            >
              <div className="d-flex justify-content-between flex-column flex-grow-1 pt-1">
                <div
                  className={`d-flex justify-content-between ${
                    researchProfileCount < 100 ? "" : "mb-2"
                  }`}
                >
                  <div className="text-14-400-20 color-title-navy">
                    Profile Status
                  </div>
                  <div className="text-14-400-20 color-title-navy">
                    {researchProfileCount >= 100 ? 100 : researchProfileCount}%
                  </div>
                </div>
                <div className="progress" style={{ height: "6px" }}>
                  <div
                    className="progress-bar bg-lime-green"
                    role="progressbar"
                    style={{ width: `${researchProfileCount}%` }}
                    aria-valuenow={researchProfileCount}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <Button
                isSquare
                text="Update Profile"
                btnStyle="primary-dark"
                className="cps-20 cpe-20 rounded h-35"
                onClick={() => {
                  dispatch(setIsGlobalProfilePreviewPopup(true));
                }}
              />
            </div>
          )}
          {registration_email_otp_status === "0" && (
            <div className="text-14-400-18 color-black-olive cmb-24">
              Just {remainPercentage}% more to unlock all benefits and{" "}
              <span
                className="text-14-500-18 color-7cff pointer"
                onClick={() => {
                  setShowEmailModel(true);
                  setModalShow(true);
                }}
              >
                Verify your email ID
              </span>
            </div>
          )}

          <div className="text-14-300-24 color-1717 cmb-14">
            Unlock Membership Perks, Research Insights, and Global Connections :
          </div>
          <div className="d-flex align-items-center gap-3 w-100 flex-wrap">
            {perks.map((o) => {
              return (
                <div
                  className="perks-block"
                  key={o?.id}
                  onClick={() => {
                    handleRedirect(o?.name);
                  }}
                >
                  <img src={o?.icons} alt="book" />
                  <span className="text-16-500-20">{o?.name}</span>
                </div>
              );
            })}
          </div>
        </div>
        <VerifyModel
          showEmailModel={showEmailModel}
          modalShow={modalShow}
          setModalShow={setModalShow}
          // onHide={() => setModalShow(false)}
          VerifyEmail={() => getVerifiyedEmail()}
        />
      </div>
    </>
  );
};
export default Welcome;
