import Button from "components/form/Button";
import Card from "components/Layout/Card";
import FilterDropdown from "components/Layout/FilterDropdown";
import Table from "components/Layout/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchAllMembershipChangeList,
  fetchMemberships,
  fetchUserEventDetails,
  getEvent,
} from "store/slices";
import { icons, limit } from "utils/constants";
import {
  getDataFromLocalStorage,
  getEventDate,
  getUserType,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";

const NewEventRequest = ({ type, resourceList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { moduleType } = params;
  const memberType = getUserType();
  const { eventTypeList } = useSelector((state) => ({
    eventTypeList: state.global.eventTypeList,
  }));
  const [isLoading, setIsLoading] = useState(true);
  const [searchPayload, setSearchPayload] = useState({
    event_name: "",
    event_type: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: limit,
    event_by_filter: "",
    type: "eventManagement",
  });
  // const [eventId, setEventId] = useState(null);
  const [list, setList] = useState([]);

  const handelChangeFilter = (val) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, event_by_filter: val, offset: 0 };
    setFilterData(newData);
    getRequestsData(newData);
  };

  const handleRedirect = (url) => {
    navigate(url);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getRequestsData(newData);
  };
  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getRequestsData(newData);
  };

  const getRequestsData = async (obj) => {
    let forData = objectToFormData(obj);
    const response = await dispatch(fetchAllMembershipChangeList(forData));
    setList(response?.data?.admin_requests || []);
    setFilterData({
      ...obj,
      total: response?.data?.result_count || 0,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getRequestsData({ ...filterData, ...searchPayload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchEventDetails = async (eventId) => {
    await dispatch(fetchMemberships());
    if (getDataFromLocalStorage("user_type") === "0") {
      await dispatch(getEvent(eventId));
    } else {
      await dispatch(
        fetchUserEventDetails({
          event_id: eventId,
          user_id: getDataFromLocalStorage("id"),
        })
      );
    }
  };

  const requestType = type === "2" ? "pending" : "raised";

  const header = [
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: { options: eventTypeList, key: "id", value: "name" },
      searchInputName: "event_type",
      title: "Event Type",
    },
    {
      isSearch: true,
      searchInputName: "event_name",
      title: "Event Name",
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: "date",
      title: "Date & Venue",
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: resourceList || [],
        key: "resource_name",
        value: "resource_name",
      },
      searchInputName: "resource_name",
      title: "Resource",
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: [
          { name: "Pending", key: "0" },
          { name: "Approved", key: "1" },
          { name: "Reject", key: "2" },
        ],
        key: "key",
        value: "name",
      },
      searchInputName: "status",
      title: "Status",
    },
    {
      isSearch: false,
      searchLable: "Edit/Delete",
      title: "Action",
    },
  ];
  const updatedHeader = [...header];
  if (type !== "2") {
    updatedHeader?.splice(2, 2);
    updatedHeader?.splice(2, 0, {
      isSearch: true,
      searchInputName: "remarks",
      title: "Remarks",
    });
  }

  const rowData = [];
  list?.forEach((elem) => {
    let obj = [
      {
        value: elem?.event_type,
      },
      {
        value: (
          <div
            className="pointer color-new-car"
            onClick={() => {
              localStorage.prevType = type;
              handleRedirect(
                `/${memberType}/${moduleType}/event-details/${elem?.event_id}/conference-details`
              );
            }}
          >
            {elem?.event_name}
          </div>
        ),
      },
      {
        value: (
          <span>
            <div>{getEventDate(elem?.start_date, elem?.end_date)}</div>
            {elem?.country_name && (
              <div>{`${elem?.city_name ? `${elem?.city_name},` : ""} ${
                elem?.country_name
              }`}</div>
            )}
          </span>
        ),
      },
      {
        value: elem?.resource,
      },
      {
        value: elem?.admin_action,
      },
      {
        value: (
          <span className="action-icon-buttons">
            {["Approved", "Rejected"]?.includes(elem?.admin_action) ? (
              <Button
                btnStyle="light-outline"
                icon={<i className="bi bi-eye" />}
                className="me-2"
                onClick={() => {
                  fetchEventDetails(elem?.event_id);
                  localStorage.prevType = type;
                  setTimeout(() => {
                    handleRedirect(
                      `/${memberType}/${moduleType}/events/register/form/${elem?.event_id}/${requestType}/${elem?.id}/view`
                    );
                  }, 1000);
                }}
                isSquare
              />
            ) : (
              <>
                <Button
                  btnStyle="light-outline"
                  icon={<i className="bi bi-eye" />}
                  className="me-2"
                  onClick={() => {
                    fetchEventDetails(elem?.event_id);
                    localStorage.prevType = type;
                    setTimeout(() => {
                    handleRedirect(
                      `/${memberType}/${moduleType}/events/register/form/${elem?.event_id}/${requestType}/${elem?.id}/view`
                    );
                  }, 1000);
                  }}
                  isSquare
                />
                {elem?.admin_action === "Pending" && type !== "3" && (
                  <Button
                    btnStyle="light-outline"
                    icon={<img src={icons.edit} alt="edit" />}
                    className=""
                    onClick={() => {
                      localStorage.prevType = type;
                      handleRedirect(
                        `/${memberType}/${moduleType}/events/${elem?.event_id}`
                      );
                    }}
                    isSquare
                  />
                )}
              </>
            )}
          </span>
        ),
      },
    ];
    if (type !== "2") {
      obj?.splice(2, 2);
      obj?.splice(2, 0, {
        value: titleCaseString(elem?.comment),
      });
    }
    rowData.push({ data: obj });
  });

  return (
    <Card className="cps-20 cpe-20 cpb-20">
      <div className="d-flex justify-content-between align-items-center cpt-28 cpb-28">
        <div className="table-title">
          {`${type === "2" ? "Pending" : "Raised"} Request`}(
          {filterData?.total || 0})
        </div>
        <div className="d-flex">
          <div className="d-flex me-3">
            <FilterDropdown
              isHideAll
              list={[
                { id: "1", name: "IFERP Events" },
                { id: "2", name: "Self Driven Events" },
              ]}
              handelChangeFilter={handelChangeFilter}
            />
          </div>

          {type === "3" && (
            <Button
              onClick={() => {
                handleRedirect(`/${memberType}/${moduleType}/events/add-event`);
              }}
              text="+ Add Event"
              btnStyle="primary-outline"
              className="h-35 text-14-500"
              isSquare
            />
          )}
        </div>
      </div>
      <Table
        header={updatedHeader}
        rowData={rowData}
        isLoading={isLoading}
        filterData={filterData}
        searchPayload={searchPayload}
        searchInputChange={handelChangeSearch}
        changeOffset={handelChangePagination}
      />
    </Card>
  );
};

export default NewEventRequest;
