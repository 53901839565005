import Card from "components/Layout/Card";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { icons } from "utils/constants";
import { objectToFormData } from "utils/helpers";
import { useDispatch } from "react-redux";
import { fetchDetailsOfTask } from "store/slices";
import Loader from "components/Layout/Loader";
import MyOldData from "./MyOldData";
import MyNewData from "./MyNewData";
import "./MyTaskDetails.scss";

const MyTaskDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id: paperId } = params;
  const [isOldTaskDetails, setIsOldTaskDetails] = useState(false);
  const [scores, setScores] = useState([
    { id: "originality", label: "Originality", score: 0 },
    {
      id: "contribution_to_the_field",
      label: "Contribution to the Field",
      score: 0,
    },
    { id: "technical_quality", label: "Technical Quality", score: 0 },
    {
      id: "clarity_of_presentation",
      label: "Clarity of Presentation",
      score: 0,
    },
    { id: "depth_of_research", label: "Depth of Research", score: 0 },
  ]);
  const [myTaskDetails, setMyTaskDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchDetails = async () => {
    setIsLoading(true);
    const response = await dispatch(
      fetchDetailsOfTask(objectToFormData({ id: paperId }))
    );
    if (response?.status === 200) {
      setMyTaskDetails(response?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myTaskDetails?.result) {
      const updatedScores = scores?.map((s) => {
        if (Object.keys(myTaskDetails?.result)?.includes(s.id)) {
          return {
            ...s,
            score: myTaskDetails?.result[s?.id], // Update the score with the API value
          };
        }
        return s;
      });

      setScores(updatedScores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTaskDetails?.result]);

  const { old_revisoin } = myTaskDetails || {};

  useEffect(() => {
    if (myTaskDetails?.old_revisoin?.length > 0) {
      setIsOldTaskDetails(isOldTaskDetails?.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTaskDetails?.old_revisoin]);
  return (
    <>
      <div id="my-task-detail-container">
        <Card className="cmb-20">
          <div className="bg-f4ff color-new-car text-14-500-17 cps-44 cpt-12 cpe-28 cpb-12 d-flex align-items-center gap-3">
            <div
              className="d-flex"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                src={icons.leftArrow}
                alt="back"
                className="h-21 me-3 pointer"
              />
            </div>
            <div className="text-20-500-25 color-black-olive">
              Review Abstract
            </div>
          </div>
        </Card>
        {isLoading ? (
          <Card className="cps-44 cpt-32 cpe-44 cpb-32">
            <div className="pt-5 pb-5">
              <Loader size="md" />
            </div>
          </Card>
        ) : (
          <>
            {old_revisoin?.length === 0 ? (
              <>
                <MyNewData
                  myTaskDetails={myTaskDetails}
                  handleSuccess={() => {
                    fetchDetails();
                  }}
                  isOriginalData
                />
              </>
            ) : (
              <>
                {old_revisoin?.map((oldElem, oldIndex) => {
                  return (
                    <>
                      <div
                        className="text-14-400-18 color-black-olive border br-5 w-100 cmb-8"
                        key={oldIndex + 1}
                      >
                        <div
                          className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                          onClick={() => {
                            setIsOldTaskDetails(
                              isOldTaskDetails === oldIndex ? false : oldIndex
                            );
                          }}
                        >
                          {oldIndex === 0 && <span>Task Details </span>}
                          {oldIndex > 1 && (
                            <span>Revision {oldIndex} Task Details </span>
                          )}
                          <i
                            className={`${
                              isOldTaskDetails === oldIndex
                                ? "bi bi-chevron-up"
                                : "bi bi-chevron-down"
                            }`}
                            style={{ fontSize: "15px" }}
                          />
                        </div>
                        {isOldTaskDetails === 0 && oldIndex === 0 && (
                          <MyOldData
                            myTaskDetails={{
                              ...oldElem,
                              conference_name: myTaskDetails?.conference_name,
                              allocated_point: myTaskDetails?.allocated_point,
                            }}
                          />
                        )}
                        {isOldTaskDetails === oldIndex && oldIndex > 0 && (
                          <MyOldData
                            myTaskDetails={{
                              ...oldElem,
                              conference_name: myTaskDetails?.conference_name,
                              allocated_point: myTaskDetails?.allocated_point,
                            }}
                          />
                        )}
                      </div>
                    </>
                  );
                })}

                {myTaskDetails?.allocated_time_limit && (
                  <div className="text-14-400-18 color-black-olive border br-5 w-100 cmb-8">
                    <div
                      className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                      onClick={() => {
                        setIsOldTaskDetails(
                          isOldTaskDetails === isOldTaskDetails?.id
                            ? false
                            : isOldTaskDetails?.id
                        );
                      }}
                    >
                      <span>Revision {old_revisoin?.length} Task Details </span>
                      <i
                        className={`${
                          isOldTaskDetails
                            ? "bi bi-chevron-up"
                            : "bi bi-chevron-down"
                        }`}
                        style={{ fontSize: "15px" }}
                      />
                    </div>
                    {isOldTaskDetails === isOldTaskDetails?.id && (
                      <>
                        <MyNewData
                          myTaskDetails={myTaskDetails}
                          handleSuccess={() => {
                            fetchDetails();
                          }}
                          isOriginalData
                        />
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MyTaskDetails;
